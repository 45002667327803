import React from 'react';
import PropTypes from 'prop-types';

const Accordion = (props) => {
  return (
    <>
      <div className="faq-question">
        <div className="container">
          <div className="row">
            <h3 className="sub-title pb-4">{props.heading}</h3>
            {/*  */}
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="One-Acc">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#one-c"
                    aria-expanded="true"
                    aria-controls=" one-c"
                  >
                    What are the claims hotline hours?
                  </button>
                </h2>
                <div
                  id="one-c"
                  className="accordion-collapse collapse show"
                  aria-labelledby="One-Acc"
                >
                  <div className="accordion-body">
                    <p className="body-title ">
                      Available to you 24 hours a day, 7 days a week
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="Two-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#two-C"
                    aria-expanded="false"
                    aria-controls="two-C"
                  >
                    Does the coverage extend to all legal firearms?
                  </button>
                </h2>
                <div
                  id="two-C"
                  className="accordion-collapse collapse"
                  aria-labelledby="Two-Acc"
                >
                  <div className="accordion-body">
                    <p className="body-title-list">
                      Lawful acts of self-defense are all covered under your
                      policy regardless of the legal firearm used
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="three-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#three-cc"
                    aria-expanded="false"
                    aria-controls="three-cc"
                  >
                    What is included with this association?
                  </button>
                </h2>
                <div
                  id="three-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="three-Acc"
                >
                  <div className="accordion-body">
                    <div className="font-list-civil">
                      <p>
                        Unlimited Civil & Criminal Defense. (No Limits, No
                        Reimbursement, 24/7 Coverage)
                      </p>
                      <p>Psychological support Included.</p>
                      <p>Expert Witness coverage Included.</p>
                      <p>
                        Firearm & Self-Defense Training & Education Included.
                      </p>
                      <p>Quarterly Firearms Newsletter Included.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="four-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#four-cc"
                    aria-expanded="false"
                    aria-controls="four-cc"
                  >
                    Why do I need to become an associate?
                  </button>
                </h2>
                <div
                  id="four-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="four-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>a. Police staffs are thin</p>
                      <p>
                        b. Police response times are down because of lack of
                        resources – requires you to think quick
                      </p>
                      <p>c. Defense cost can be over 6 figures</p>
                      <p>
                        d. You buy the gun for protection. This association is
                        your extra line of defense.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="five-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#five-cc"
                    aria-expanded="false"
                    aria-controls="five-cc"
                  >
                    If I shoot someone defending myself what should be the first
                    thing I do?
                  </button>
                </h2>
                <div
                  id="five-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="five-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>
                        <span>CALL 9-1-1.</span> Then call our 24/7 attorney hot
                        line.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="six-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#six-cc"
                    aria-expanded="false"
                    aria-controls="six-cc"
                  >
                    How do I contact customer service?
                  </button>
                </h2>
                <div
                  id="six-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="six-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>
                        Call: 833-887-2327 or email info@protectwithbear.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="seven-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#seven-cc"
                    aria-expanded="false"
                    aria-controls="seven-cc"
                  >
                    How do I reset my password?
                  </button>
                </h2>
                <div
                  id="seven-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="seven-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>
                        Click the Reset your password link below the password
                        section on your member log in portal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="eight-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#eight-cc"
                    aria-expanded="false"
                    aria-controls="eight-cc"
                  >
                    What do I get when I join?
                  </button>
                </h2>
                <div
                  id="eight-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="eight-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p className="mb-2">
                        Our association offers 3 pillars of extraordinary value
                        to help our members have the best protection and
                        education one can buy.
                      </p>
                      <p>
                        EDUCATION - expert information on legally exercising
                        your Second Amendment right.
                      </p>
                      <p>
                        ATTORNEY HOTLINE - immediate real-time assistance from
                        experienced legal claims experts
                      </p>
                      <p>
                        FINANCIAL SUPPORT & LEGAL PROTECTION - upfront payment,
                        no out-of-pocket costs, nothing to repay
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="nine-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#nine-cc"
                    aria-expanded="false"
                    aria-controls="nine-cc"
                  >
                    I don’t have a concealed carry license. Is the association
                    still right for me?
                  </button>
                </h2>
                <div
                  id="nine-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="nine-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>Everyone needs self defense protection.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="ten-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#ten-cc"
                    aria-expanded="false"
                    aria-controls="ten-cc"
                  >
                    Can I upgrade my level later?
                  </button>
                </h2>
                <div
                  id="ten-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="ten-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>
                        Of course. Upgrading will always be available in the
                        member portal.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="eleven-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#eleven-cc"
                    aria-expanded="false"
                    aria-controls="eleven-cc"
                  >
                    What training is included with my membership?
                  </button>
                </h2>
                <div
                  id="eleven-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="eleven-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>
                        A full curriculum of self defense videos and articles
                        are available.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="twelve-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#twelve-cc"
                    aria-expanded="false"
                    aria-controls="twelve-cc"
                  >
                    Can I pay my dues monthly?
                  </button>
                </h2>
                <div
                  id="twelve-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="twelve-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>
                        Yes! You have the option to pay your dues either monthly
                        or annually.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="thirteen-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#thirteen-cc"
                    aria-expanded="false"
                    aria-controls="thirteen-cc"
                  >
                    If I go to jail in self-defense, will you post my bail?
                  </button>
                </h2>
                <div
                  id="thirteen-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="thirteen-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>
                        Yes, for an additional for $4 per month or $35 per year,
                        bail bond protection is available to our associates up
                        to $100,000.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="fourteen-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#fourteen-cc"
                    aria-expanded="false"
                    aria-controls="fourteen-cc"
                  >
                    How long does it take for my ID card to show up?
                  </button>
                </h2>
                <div
                  id="fourteen-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="fourteen-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>
                        Your associate ID card will be available online
                        immediately after joining.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="fifteen-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#fifteen-cc"
                    aria-expanded="false"
                    aria-controls="fifteen-cc"
                  >
                    How long does it take for my ID card to show up?
                  </button>
                </h2>
                <div
                  id="fifteen-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="fifteen-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>Yes, email us at info@protectwithbear.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="sixteen-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#sixteen-cc"
                    aria-expanded="false"
                    aria-controls="sixteen-cc"
                  >
                    Can I change my association status?
                  </button>
                </h2>
                <div
                  id="sixteen-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="sixteen-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <p>Yes, email us at info@protectwithbear.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="seventeen-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#seventeen-cc"
                    aria-expanded="false"
                    aria-controls="seventeen-cc"
                  >
                    Definitions
                  </button>
                </h2>
                <div
                  id="seventeen-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="seventeen-Acc"
                >
                  <div className="accordion-body">
                    <div className="body-title-sub">
                      <ol>
                        <li>
                          <span>Civil Defense coverage</span>
                          allows you to choose your own attorney in the event
                          you are sued after a self-defense incident.
                        </li>
                        <li>
                          <span>Criminal Defense coverage</span>
                          provides legal support in the event you are criminally
                          charged after a self-defense incident. (You choose
                          your own attorney)
                        </li>
                        <li>
                          <span>Psychological support</span>
                          provides coverage for counseling sessions due to
                          stress/trauma experienced from a self-defense
                          incident.
                        </li>
                        <li>
                          <span>Expert Witness coverage</span> will provide an
                          “expert witness” to testify on your behalf.
                        </li>
                        <li>
                          <span>Multi state coverage</span>
                          Multi state coverage offers you protection in 50
                          states. Residents of New York, New Jersey, and
                          Washington do not qualify for our membership.
                        </li>
                        <li>
                          <span>Bail Bond Reimbursement</span>
                          provides money for bail up to $100,000.
                        </li>
                        <li>
                          <span>Additional Associate:</span>
                          extends coverage to a spouse or household partner at
                          the limit of coverage selected.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="eighteen-Acc">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#eighteen-cc"
                    aria-expanded="false"
                    aria-controls="eighteen-cc"
                  >
                    Standard Association Benefits
                  </button>
                </h2>
                <div
                  id="eighteen-cc"
                  className="accordion-collapse collapse"
                  aria-labelledby="eighteen-Acc"
                >
                  <div className="accordion-body">
                    <div className="emergency-title">
                      <ol>
                        <li>24/7 365 Emergency Hotline</li>
                        <li>Coverage extends to all legal weapons.</li>
                        <li>Member ID card is inside of your member portal.</li>
                        <li>Multiple payment options.</li>
                        <li>
                          Home/Umbrella policies do not cover acts of
                          self-defense.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
Accordion.propTypes = {
  heading: PropTypes.string,
};

export default Accordion;
