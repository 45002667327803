import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuthToken } from '../utils/Storage';

const Footer = () => {
  const [auth, setAuth] = useState(true);

  useEffect(() => {
    let userToken = getAuthToken('userToken');
    userToken ? setAuth(false) : setAuth(true);
    console.log(auth);
  }, []);

  return (
    <>
      <footer>
        {/* {auth && ( */}
        <div className="footer-menu">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="logo-footer">
                  <Link to="/">
                    <img src="../../images/footer-logo.webp" alt="" />
                  </Link>
                  <div className="email">
                    <span className="label">
                      Email:{' '}
                      <Link
                        className="information"
                        to="mailto:info@protectwithbear.com"
                      >
                        info@protectwithbear.com
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="footer-linking">
                  <p className="title-footer-link">NAVIGATE</p>
                  <ul>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/education">Education</Link>
                    </li>
                    <li>
                      <Link to="/coverage">Get Coverage</Link>
                    </li>
                    <li>
                      <Link to="/events">Events</Link>
                    </li>
                    <li>
                      <Link to="/faqpage">Faq</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="footer-linking">
                  <p className="title-footer-link">RESOURCES</p>
                  <ul>
                    <li>
                      <Link to="/Company-vision">Company Vision</Link>
                    </li>
                    <li>
                      <Link to="/carryresponsibly">Carry Responsibly</Link>
                    </li>
                    <li>
                      <Link to="/support">Help & Support</Link>
                    </li>
                    <li>
                      <Link to="/affiliate-net-work">Affiliate Network</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">Terms & Condition</Link>
                    </li>
                    <li>
                      <Link to="/ccw-insurance">What Is Ccw Insurance?</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}

        <div className="footer__content-bottom">
          <div className="container">
            <div className="row">
              <div className="right-to-bear-footer">
                <div className="copy-right order-3 order-lg-1">
                  <span>
                    © 2023,<Link to="/">Right To Bear</Link>
                  </span>
                </div>
                <div className="bottom-footer-term-condition order-2 order-lg-2">
                  <Link to="/terms-conditions">Terms & Conditions</Link>
                </div>

                <div className="footer__list-social order-1 order-lg-3">
                  <ul>
                    <li>
                      <Link to="https://twitter.com/RTBAssociation">
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="ttps://www.facebook.com/righttobearlegaldefense/?show_switched_toast=0&show_invite_t[…]w_community_rollback=0&show_follower_visibility_disclosure=0">
                        <i className="fa fa-facebook-official"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to=" https://www.youtube.com/channel/UCWpAALDAKY5bk7U_cHoxvDQ">
                        <i className="fa fa-youtube-play"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
