import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const CardItems = (props) => {
  const [displayText, setDisplayText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const text = props.CardText;
    const maxLength = 170; // Maximum number of characters to display before truncating
    if (text.length > maxLength) {
      setDisplayText(text.substring(0, maxLength) + '...');
    } else {
      setDisplayText(text);
    }
  }, [props.CardText]);

  const handleNavigate = (id) => {
    navigate('/event-view', {
      state: {
        data: {
          id: id,
        },
      },
    });
  };
  return (
    <>
      <div className="event-card">
        <div className="card">
          <img src={props.ImagesCard} alt="event-1" className="img-fluid" />
          <div className="card-body">
            <h4 className="card-heading">{props.CardHeading}</h4>
            <p className="card-titles">{props.DateCard}</p>
            <p className="card-text">
              {displayText}{' '}
              <a
                className="r-m"
                onClick={() => handleNavigate(props.eventData.id)}
              >
                Read more
              </a>
            </p>
            <a href={props.HrefLink}>
              Sign Up
              <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

CardItems.propTypes = {
  ImagesCard: PropTypes.node.isRequired,
  CardHeading: PropTypes.string,
  DateCard: PropTypes.string,
  CardText: PropTypes.string,
  HrefLink: PropTypes.string,
  eventData: PropTypes.object,
};

export default CardItems;
