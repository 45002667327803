import React from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <>
      <MainLayout>
        <div className="about-banner">
          <div className="about-outer">
            <h1 className="banner-heading">
              SPEAK FREELY, LIVE FULLY, DEFEND LIBERTY
            </h1>
          </div>
        </div>
        <div className="liberty-outer">
          <div className="container">
            <div className="liberty-text">
              <h3 className="title-text">ABOUT RIGHT TO BEAR</h3>
              <p className="sub-title-2">
                Offering the most value-centered, customized self-defense
                association in the country – BEST in protection, BEST in
                resources, BEST in price!
              </p>
              <p className="bear-text">
                Right To Bear is an American self-defense association that is
                dedicated to the preservation of individual liberty, for all
                freedom-loving people, in the United States, who desire to
                defend their family with the legal protection necessary, in an
                era of elites trying to control their lives. As a member of
                Right To Bear, protecting your family means you never have to
                defend them alone.
              </p>
            </div>
            <div className="percentage">
              <img src="../../images/line.svg" alt="" className="img-fluid" />
            </div>
            <div className="right-status">
              <div className="row">
                <div className="col-md-4">
                  <div className="status-right-bear">
                    <h4>Who?</h4>
                    <p>
                      Right To Bear was formed to defend our liberty with the
                      goal of protecting the responsible gun owner.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="status-right-bear px-md-3">
                    <h4>What?</h4>
                    <p>
                      First class self-defense protection, training, education,
                      discounts to industry partners, newsletters, and more.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="status-right-bear">
                    <h4>Why?</h4>
                    <p>
                      With violent crime on the rise and police response time
                      slowing; YOU may be your only defense.
                    </p>
                  </div>
                </div>
                <div className="join-btn">
                  <Link
                    to="/coverage"
                    className="theme-button login-button py-4"
                  >
                    Join Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="responsible-user-gun">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 g-0">
                <img
                  src="../../images/aboutSection.webp"
                  alt="aboutSection"
                  className="w-100"
                />
              </div>
              <div className="col-md-6 g-0">
                <div className="gun-man">
                  <div className="user-gun-text">
                    <h4 className="sub-title">
                      RIGHT TO BEAR IS SELF DEFENSE PROTECTION FOR THE
                      RESPONSIBLE GUN OWNER
                    </h4>
                    <p>
                      It provides the BEST value for coverage and affordability
                      in its class
                    </p>
                    <p>
                      Along with your membership, you will receive our “Bear
                      Facts” weekly educational feature which will always give
                      the “Bear Facts” of situational awareness, trauma
                      avoidance, and simply what and what not to do if you have
                      faced a self-defense situation with your firearm
                    </p>
                    <p>
                      We believe in protecting freedom, protecting family, and
                      protecting YOU. Let us secure your piece of mind so you
                      can stand your ground with Right To Bear!
                    </p>
                    <Link
                      to="/carryresponsibly"
                      className="button-white mobile-width"
                    >
                      Learn More
                    </Link>
                  </div>
                  <div className="rifle-percentage">
                    <img
                      src="../../images/line.svg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="talk-about d-none">
          <div className="container">
            <div className="row">
              <div className="brand-talk">
                <h5 className="title-text">ADD SUBTITLE</h5>
                <h3>Talk about your brand</h3>
                <p>
                  Share information about your brand with your customers.
                  Describe a product, make announcements, or welcome customers
                  to your store.
                </p>
                <button
                  type="submit"
                  className="theme-button disable login-button"
                >
                  button label
                </button>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default About;
