import React, { useEffect, useState } from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link } from 'react-router-dom';
import AccountForm from '../components/AccountForm';
import Cookies from 'js-cookie';
import { addAddress, updateAddress } from '../utils/endpoint';
import { deleteApi, getApi } from '../utils/api';

export const AccountAddresses = () => {
  const [addressList, setAddressList] = useState([]);
  const [editData, setEditData] = useState({});
  const [deleteID, setDeleteID] = useState('');
  const access_token = Cookies.get('userToken');
  const fetchAddressList = () => {
    getApi(addAddress, access_token)
      .then((res) => {
        if (res?.data?.success) {
          setAddressList(res?.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchAddressList();
  }, []);

  const handleDelete = (e) => {
    e.preventDefault();
    deleteApi(updateAddress + deleteID, access_token)
      .then((res) => {
        if (res?.data?.success) {
          const modalClose = document.getElementsByClassName('cancel')[0];
          modalClose.click();
          fetchAddressList();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <AccountMainLayout>
        <div className="account-login">
          <div className="container">
            <div className="return-details">
              <div className="row">
                <h3 className="profile">Profile Addresses</h3>
                <Link to="/account" className="return-account">
                  Return to Account details
                </Link>
                <div className="text-center mt-4">
                  <Link
                    to="#"
                    className="button-golden button-black"
                    style={{
                      fontWeight: '500',
                      fontSize: '14px',
                      color: 'var(--golden)',
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop1"
                  >
                    Add a New Profile Address
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="default-user">
            <div className="container">
              <div className="row gx-1">
                {addressList &&
                  addressList?.map((item) => (
                    <div className="mb-2 col-list" key={item.id}>
                      <div className="card default-card d-flex flex-column  h-100 pb-3">
                        <div className="default-body">
                          {item?.default === 1 && <h2>Default</h2>}
                          <p className="card-user-id-text">
                            {item.firstname} {item?.lastname}
                            <br />
                            {item?.company &&
                              item?.company !== '' &&
                              item?.company !== 'null' && (
                                <>
                                  {item?.company}
                                  <br />
                                </>
                              )}
                            {item?.street_address1}
                            <br />
                            {item?.street_address2 !== '' &&
                              item?.street_address2 !== null && (
                                <>
                                  {item?.street_address2} <br />{' '}
                                </>
                              )}
                            {item?.city} {item?.state} {item.postal_code}
                            <br />
                            {item?.country}
                          </p>
                        </div>
                        <div
                          className="default-bottom mt-auto ml-auto mr-auto"
                          style={{ marginTop: 'auto' }}
                        >
                          <Link
                            to=""
                            className="button-black  size-button size-border me-4"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            onClick={() => setEditData(item)}
                          >
                            Edit
                          </Link>
                          <Link
                            to=""
                            className="button-black  size-button size-border"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            onClick={() => setDeleteID(item.id)}
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body d-flex">
                <AccountForm
                  Heading="Edit address"
                  ButtonFirst="Update address"
                  fetchAddressList={fetchAddressList}
                  personalDetail={editData}
                />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="staticBackdrop1"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body d-flex">
                <AccountForm
                  Heading="Add a new profile address"
                  ButtonFirst="Add address"
                  fetchAddressList={fetchAddressList}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body delete-text">
                <h3>protectwithbear.com says</h3>
                <p>Are you sure you wish to delete this address?</p>
              </div>
              <div className="modal-footer cancel-button">
                <button
                  type="button"
                  className="cancel"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="cancel ok"
                  onClick={handleDelete}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </AccountMainLayout>
    </>
  );
};
