import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import { TailSpin, ThreeDots } from 'react-loader-spinner';
import { getApi, postApi } from '../utils/api';
import {
  STRIPE_PUBLISHABLE_KEY,
  checkCouponApi,
  subscribe,
} from '../utils/endpoint';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { generatePrice } from '../utils/helpers';
import { state_city } from '../utils/state_cities';
import { deleteLocalData, getLocalData } from '../utils/Storage';
import ErrorBanner from './ErrorBanner';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = () => {
  const { billingDetail, personalDetail } = useSelector(
    (state) => state.signup
  );
  const navigate = useNavigate();

  const [errorMsg, SetErrorMsg] = useState('');
  const [isError, SetIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [associateCode, setAssociateCode] = useState(
    getLocalData('referralCode') ? true : false
  );
  const [formData, setFormData] = useState({
    address: personalDetail?.address ? personalDetail?.address : '',
    address2: personalDetail?.address2 ? personalDetail?.address2 : '',
    state: personalDetail?.state ? personalDetail?.state : '',
    city: personalDetail?.city ? personalDetail?.city : '',
    zipCode: personalDetail?.zipCode ? personalDetail?.zipCode : '',
    associateCode: '',
    referralCode: getLocalData('referralCode')
      ? getLocalData('referralCode')
      : '',
  });
  const [discount, setDiscount] = useState('');
  const [discountApplied, setDiscountApplied] = useState({});
  const [error, setError] = useState('');
  const [hide, setHide] = useState(false);
  const [hideBilling, setHideBilling] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [total, setTotal] = useState(billingDetail?.total);
  const [appliedSuccess, setAppliedSuccess] = useState(false);
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false);
  const [isTermAndCondition, setIsTermAndCondition] = useState(false);

  const [showError, setShowError] = useState(false);
  const [showErrorDetail, setShowErrorDetail] = useState({
    isError: true,
    message: '',
  });

  let statelist = Object.keys(state_city);
  let checkIsSubscribed = Cookies.get('isSubscribed');

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    // if (isMobile) {
    if (!isTermAndCondition) {
      setShowErrorDetail({
        isError: false,
        message: 'Please agree to Terms & Conditions.',
      });
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setShowErrorDetail({
          isError: true,
          message: '',
        });
      }, 3000);
      return;
    }
    // }

    try {
      setIsLoading(true);
      setIsPaymentSuccess(true);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        SetIsError(true);
        SetErrorMsg(error.message);
        setTimeout(() => {
          SetIsError(false);
        }, 4000);
        setIsLoading(false);
        setIsPaymentSuccess(false);
      }

      if (paymentMethod) {
        const formdata = new FormData();
        formdata.append('payment_method_id', paymentMethod.id);
        formdata.append('referral_code', formData?.referralCode);
        formdata.append('associate_code', formData?.associateCode);
        formdata.append(
          'plan_name',
          billingDetail?.selectedPackage?.billingOption?.planName
        );
        const { stripe_coupon_id } = discountApplied;
        if (stripe_coupon_id) {
          formdata.append('coupon', stripe_coupon_id);
        }

        billingDetail?.selectedStripeIds.map((item) => {
          formdata.append(`stripe_price_ids[]`, item);
        });
        let access_token = Cookies.get('userToken');
        postApi(subscribe, formdata, access_token)
          .then((res) => {
            if (res.data.success) {
              setIsLoading(false);
              setSuccess(true);
              setIsPaymentSuccess(true);
              Cookies.set('isSubscribed', true);
              localStorage.clear('joinEmail');
              deleteLocalData('referralCode');
              // setTimeout(() => {
              //   setSuccess(false);
              //   navigate("/account");
              // }, 4000);
            } else {
              SetIsError(true);
              SetErrorMsg('Something went wrong. Please try again!');
              setIsPaymentSuccess(false);
              setTimeout(() => {
                SetIsError(false);
              }, 4000);
              setIsLoading(false);
            }
          })
          .catch((e) => {
            SetIsError(true);
            SetErrorMsg(e.response?.data?.message);
            setIsPaymentSuccess(false);
            setTimeout(() => {
              SetIsError(false);
            }, 4000);
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.error('Error:', error.message);
      setIsPaymentSuccess(false);
    }
  };

  const handleClose = () => {
    setShowError(false);
    setShowErrorDetail({
      isError: true,
      message: '',
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
        setHide(false);
        setHideBilling(false);
      } else {
        setHide(false);
        console.log(isMobile);
        setHideBilling(true);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleApply = (e) => {
    e.preventDefault();
    if (discount) {
      setIsLoadingCoupon(true);
      getApi(checkCouponApi + discount)
        .then((res) => {
          if (res?.data?.success) {
            if (res?.data?.data !== null) {
              setDiscountApplied(res?.data?.data);
              setAppliedSuccess(true);
              let discountAmount, amount;
              if (res?.data?.data?.type === 'percentage') {
                discountAmount = (total * res?.data?.data?.amount) / 100;
                amount = total - discountAmount;
              } else {
                discountAmount = (res?.data?.data?.amount / 100).toFixed(2);
                amount = total - discountAmount;
              }
              setTotal(amount);
              setIsLoadingCoupon(false);
            } else {
              setError('This coupon code is invalid or expired');
              setIsLoadingCoupon(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingCoupon(false);
        });
    }
  };
  const handleRemove = (e) => {
    e.preventDefault();
    let discountAmount;
    let amount;
    if (discountApplied?.type === 'percentage') {
      if (!isNaN(total) && !isNaN(discountApplied?.amount)) {
        amount = total / (1 - discountApplied?.amount / 100);
      }
    } else {
      discountAmount = Number(discountApplied?.amount) / 100;
      amount = Number(total) + discountAmount;
    }
    setTotal(amount);
    setDiscount('');
    setDiscountApplied({});
    setAppliedSuccess(false);
  };

  useEffect(() => {}, []);

  return (
    <form onSubmit={handleSubmit}>
      {showError && (
        <ErrorBanner error={showErrorDetail} handleClose={handleClose} />
      )}
      <div className="row">
        <div className="col-md-6">
          <div className="py-4 pop-list">
            {success === false && !checkIsSubscribed && (
              <>
                <button className="back-bb" type="button">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  <span className="">Back to previous details</span>
                </button>
                <div className="card card-payment">
                  <h5 className="bill-address list-bil">CARD DETAILS</h5>
                  <div className="row g-3">
                    <div className="col-lg-5">
                      <CardNumberElement className="form-control input-outline" />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <CardExpiryElement className="form-control input-outline" />
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <CardCvcElement className="form-control input-outline" />
                    </div>
                  </div>
                </div>
                <div className="card card-payment">
                  <div className="row">
                    {!associateCode && (
                      <span>
                        <a
                          className="referral-code-css"
                          onClick={() => setAssociateCode(true)}
                        >
                          Do you have an associate code?
                        </a>
                      </span>
                    )}
                    {associateCode && (
                      <>
                        <div className="col-12">
                          <span
                            className="close-input"
                            onClick={() => setAssociateCode(false)}
                          >
                            Close
                          </span>
                        </div>
                        <div className="col-md-6">
                          <small>Associate Code</small>
                          <input
                            type="text"
                            name="associateCode"
                            className="form-control input-outline"
                            // placeholder="Associate Code"
                            value={formData.associateCode}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-md-6">
                          <small>Referral Code</small>
                          <input
                            type="text"
                            name="referralCode"
                            className="form-control input-outline"
                            // placeholder="Referral Code"
                            value={formData.referralCode}
                            onChange={handleInputChange}
                            disabled={
                              getLocalData('referralCode') ? true : false
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="card card-payment">
                  <div className="row">
                    <div className="billing-list-add">
                      <h5 className="bill-address list-bil">BILLING ADDRESS</h5>

                      {hideBilling && (
                        <p
                          className="d-block justify-content-evenly"
                          onClick={() => setHideBilling(!hideBilling)}
                        >
                          <span className="me-2"> Hide </span>{' '}
                          <i className="fa fa-angle-up"></i>
                        </p>
                      )}
                      {!hideBilling && (
                        <p
                          className="d-block justify-content-evenly"
                          onClick={() => setHideBilling(!hideBilling)}
                        >
                          <span className="me-2">Show</span>{' '}
                          <i className="fa fa-angle-down"></i>
                        </p>
                      )}
                    </div>
                    {hideBilling && (
                      <>
                        <div className="col-md-12">
                          <label
                            htmlFor="address"
                            className="form-label label-text"
                          >
                            Street Address 1*
                          </label>
                          <input
                            type="address"
                            name="address"
                            className="form-control input-outline"
                            // placeholder="Street Address 1*"
                            value={formData.address}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="address2"
                            className="form-label label-text"
                          >
                            Street Address 2
                          </label>
                          <input
                            type="address"
                            name="address2"
                            className="form-control input-outline"
                            // placeholder="Street Address 2"
                            onChange={handleInputChange}
                            value={formData.address2}
                          />
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <label
                            htmlFor="state_"
                            className="form-label label-text"
                          >
                            State
                          </label>
                          <select
                            id="state_"
                            className="form-select input-outline"
                            name="state"
                            value={formData.state}
                            onChange={handleInputChange}
                            required
                          >
                            <option value=""> Select State</option>
                            {statelist.map((item, index) => (
                              <option value={item} key={index}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label
                            htmlFor="inputCity"
                            className="form-label label-text"
                          >
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputCity"
                            // placeholder="City"
                            name="city"
                            onChange={handleInputChange}
                            value={formData.city}
                            required
                          />
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <label
                            htmlFor="inputZip"
                            className="form-label label-text"
                          >
                            Postal/ZIP code
                          </label>
                          <input
                            type="text"
                            className="form-control input-outline"
                            id="inputZip"
                            // placeholder="Postal/ZIP code"
                            name="zipCode"
                            onChange={handleInputChange}
                            value={formData.zipCode}
                            maxLength="5"
                            onKeyPress={(e) => {
                              const regex = /^[0-9\b]+$/;
                              if (!regex.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            required
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="card border-0">
                  <button
                    type="submit"
                    className={`theme-button d-none   stripe black-button login-button sold  mt-3 ${
                      !stripe || !elements || (success && 'disable')
                    }`}
                  >
                    {isLoading ? (
                      <TailSpin
                        height="30"
                        width="30"
                        color="#fff"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{ display: 'contents' }}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      'Pay now'
                    )}
                  </button>
                  {isError && (
                    <h6 className="text-center text-danger d-none d-md-block error-mag">
                      {errorMsg}
                    </h6>
                  )}
                </div>
              </>
            )}
            {(success || checkIsSubscribed) && (
              <>
                <div className="thank-you d-none">
                  <h3 className="text-success text-align-list">
                    Thank you, {personalDetail?.firstName}!
                  </h3>
                  <h4 className="details-order">Order details</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <h6 className="information">Contact Information</h6>
                      <p>{personalDetail?.email}</p>
                    </div>
                    <div className="col-md-6">
                      <h6 className="add-bill mb-0 mb-md-2">Billing Address</h6>
                      {Object.keys(personalDetail).length > 0 && (
                        <p className="account-sub-heading">
                          {personalDetail?.name || personalDetail?.firstName}{' '}
                          {personalDetail?.last_name ||
                            personalDetail?.lastName}
                          <br />
                          {personalDetail?.address}
                          <br />
                          {personalDetail?.state +
                            ' ' +
                            personalDetail?.zipCode}
                          <br />
                          United States
                        </p>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    className={`theme-button black-button login-button  mt-3`}
                    onClick={() => navigate('/account', { replace: true })}
                  >
                    Continue
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        {success === false && !checkIsSubscribed && (
          <div className="col-md-6 gx-0">
            <div className="items-plan">
              <div className="summary-order">
                <h4 className="ps-2">
                  Order summary (
                  {billingDetail?.selectedPackage?.addons
                    ? billingDetail?.selectedPackage?.addons?.length + 1 + 1
                    : 0 + 1 + 1}
                  )
                </h4>
                {hide && (
                  <p
                    className="d-block justify-content-evenly"
                    onClick={() => setHide(!hide)}
                  >
                    <span className="me-2"> Hide </span>{' '}
                    <i className="fa fa-angle-up"></i>
                  </p>
                )}
                {!hide && (
                  <p
                    className="d-block justify-content-evenly"
                    onClick={() => setHide(!hide)}
                  >
                    <span className="me-2">Show</span>{' '}
                    <i className="fa fa-angle-down"></i>
                  </p>
                )}
              </div>
              <table className="table width-cuman">
                <tbody>
                  {hide && (
                    <>
                      <tr>
                        <td style={{ width: '350px' }}>
                          <p className="plan plan-2">
                            {/* <div className="plan-box-camera">
                          <img src="../images/camera.svg" alt="" />
                        </div> */}
                            <div>
                              {console.log(
                                billingDetail?.selectedPackage?.addons?.length +
                                  1 +
                                  1
                              )}
                              {
                                billingDetail?.selectedPackage?.billingOption
                                  ?.planName
                              }
                              <div className="d-none">
                                <small>
                                  {
                                    billingDetail?.selectedPackage
                                      ?.billingOption?.planName
                                  }
                                </small>
                              </div>
                            </div>
                          </p>
                        </td>
                        <td>
                          <p className="table-text-right">
                            {billingDetail?.selectedPackage?.billingOption
                              ?.planPrice &&
                              generatePrice(
                                billingDetail?.selectedPackage?.billingOption
                                  ?.planPrice
                              )}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="plan plan-2">
                            {/* <div className="plan-box-camera">
                          <img src="../images/camera.svg" alt="" />
                        </div> */}
                            <p className="plan">One Time Registration Fee</p>
                          </div>
                        </td>
                        <td>
                          <p className="table-text-right">
                            {generatePrice(15)}
                          </p>
                        </td>
                      </tr>
                      {billingDetail?.selectedPackage?.addons &&
                        billingDetail?.selectedPackage?.addons.map(
                          (item, index) => (
                            <tr key={index}>
                              <td>
                                <p className="plan">{item.planName}</p>
                                <small className="d-none">
                                  {item.planName}
                                </small>
                              </td>
                              <td>
                                <p className="table-text-right">
                                  {item.planPrice &&
                                    generatePrice(item.planPrice)}
                                </p>
                              </td>
                            </tr>
                          )
                        )}
                    </>
                  )}
                  <tr className={success && !appliedSuccess ? 'd-none' : ''}>
                    <td className="discount">
                      <input
                        type="text"
                        className="form-control discount-input"
                        placeholder="Discount code"
                        onChange={(e) => {
                          setDiscount(e.target.value);
                          setError('');
                        }}
                        value={discount}
                        name="discount"
                        disabled={appliedSuccess}
                      />
                      {error && <div className="text-danger m-1">{error}</div>}
                      {appliedSuccess && (
                        <>
                          <a
                            className={
                              isPaymentSuccess
                                ? 'discount-remove disable'
                                : 'discount-remove '
                            }
                            onClick={handleRemove}
                          >
                            Remove
                          </a>
                          <p className="text-success">
                            Coupon code applied successfully.
                            <u className="remove-coupon ms-5 "></u>
                          </p>
                        </>
                      )}
                    </td>
                    <td className="table-text-right">
                      <button
                        type="button"
                        className={` apply-button ${
                          appliedSuccess ? 'disabled' : ''
                        }`}
                        onClick={handleApply}
                        disabled={appliedSuccess}
                      >
                        {isLoadingCoupon ? (
                          <ThreeDots
                            height="25"
                            width="40"
                            radius="9"
                            color="#fff"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        ) : (
                          'Apply'
                        )}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="plan">Subtotal</p>
                    </td>
                    <td>
                      <p className="table-text-right">
                        {billingDetail?.subTotal &&
                          generatePrice(billingDetail?.subTotal)}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="plan">Tax</p>
                    </td>
                    <td>
                      <p className="table-text-right">
                        {billingDetail?.tax &&
                          generatePrice(billingDetail?.tax)}
                      </p>
                    </td>
                  </tr>
                  {appliedSuccess && (
                    <tr>
                      <td>
                        <p className="plan">Discount</p>
                      </td>
                      <td>
                        <p className="table-text-right">
                          {discountApplied &&
                          discountApplied?.type === 'percentage'
                            ? discountApplied?.amount + '%'
                            : '$' + (discountApplied?.amount / 100).toFixed(2)}
                        </p>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <p className="plan" style={{ fontWeight: '600' }}>
                        Total
                      </p>
                    </td>
                    <td>
                      <p
                        className="table-text-right"
                        style={{
                          fontWeight: '600',
                          width: '100px',
                          marginLeft: 'auto',
                        }}
                      >
                        USD {billingDetail?.total && generatePrice(total)}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              {success === false && (
                <div className="payment-check-now ps-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      checked={isTermAndCondition}
                      onChange={(e) => setIsTermAndCondition(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      I understand that one or more items in my cart is a
                      deferred or recurring purchase. By continuing, I agree to
                      the <Link to="#">cancellation policy</Link> and authorize
                      you to charge my payment method at the prices, frequency,
                      and dates listed on this page until my order is fulfilled
                      or I cancel, if permitted.
                    </label>
                  </div>

                  <button
                    type="submit"
                    className={`theme-button w-100 login-button mt-3 ${
                      !stripe || !elements || (success && 'disable')
                    }`}
                  >
                    {isLoading ? (
                      <TailSpin
                        height="30"
                        width="30"
                        color="#fff"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{ display: 'contents' }}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      'Pay now'
                    )}
                  </button>
                  {isError && (
                    <h6 className="text-center text-danger">{errorMsg}</h6>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <>
        {(success || checkIsSubscribed) && (
          <div className="thank-you mb-4">
            <div className="container-fluid">
              <div className="row">
                <h3 className="text-success text-align-list text-center mb-5">
                  Thank you, {personalDetail?.firstName}!
                </h3>
                <div className="col-lg-6">
                  <h4 className="details-order">Order details</h4>
                  <div className="contact-details">
                    <div className="details">
                      <h6 className="information">Contact Information</h6>
                      <p>{personalDetail?.email}</p>
                    </div>
                    <div className="details-address">
                      <h6 className="add-bill mb-0 mb-md-2">Billing Address</h6>
                      {Object.keys(personalDetail).length > 0 && (
                        <p className="account-sub-heading">
                          {personalDetail?.name || personalDetail?.firstName}{' '}
                          {personalDetail?.last_name ||
                            personalDetail?.lastName}
                          <br />
                          {personalDetail?.address}
                          <br />
                          {personalDetail?.state +
                            ' ' +
                            personalDetail?.zipCode}
                          <br />
                          United States
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="items-plan item-plan-new">
                    <div className="summary-order">
                      <h4 className="ps-2">
                        Order summary (
                        {billingDetail?.selectedPackage?.addons
                          ? billingDetail?.selectedPackage?.addons?.length +
                            1 +
                            1
                          : 0 + 1 + 1}
                        )
                      </h4>
                      {hide && (
                        <p
                          className="d-block justify-content-evenly"
                          onClick={() => setHide(!hide)}
                        >
                          <span className="me-2"> Hide </span>{' '}
                          <i className="fa fa-angle-up"></i>
                        </p>
                      )}
                      {!hide && (
                        <p
                          className="d-block justify-content-evenly"
                          onClick={() => setHide(!hide)}
                        >
                          <span className="me-2">Show</span>{' '}
                          <i className="fa fa-angle-down"></i>
                        </p>
                      )}
                    </div>

                    <table className="table width-cuman">
                      <tbody>
                        {hide && (
                          <>
                            <tr>
                              <td style={{ width: '350px' }}>
                                <p className="plan plan-2">
                                  {/* <div className="plan-box-camera">
                          <img src="../images/camera.svg" alt="" />
                        </div> */}
                                  <div>
                                    {console.log(
                                      billingDetail?.selectedPackage?.addons
                                        ?.length +
                                        1 +
                                        1
                                    )}
                                    {
                                      billingDetail?.selectedPackage
                                        ?.billingOption?.planName
                                    }
                                    <div className="d-none">
                                      <small>
                                        {
                                          billingDetail?.selectedPackage
                                            ?.billingOption?.planName
                                        }
                                      </small>
                                    </div>
                                  </div>
                                </p>
                              </td>

                              <td>
                                <p className="table-text-right">
                                  {billingDetail?.selectedPackage?.billingOption
                                    ?.planPrice &&
                                    generatePrice(
                                      billingDetail?.selectedPackage
                                        ?.billingOption?.planPrice
                                    )}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="plan plan-2">
                                  {/* <div className="plan-box-camera">
                          <img src="../images/camera.svg" alt="" />
                        </div> */}
                                  <p className="plan">
                                    One Time Registration Fee
                                  </p>
                                </div>
                              </td>
                              <td>
                                <p className="table-text-right">
                                  {generatePrice(15)}
                                </p>
                              </td>
                            </tr>
                            {billingDetail?.selectedPackage?.addons &&
                              billingDetail?.selectedPackage?.addons.map(
                                (item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <p className="plan">{item.planName}</p>
                                      <small className="d-none">
                                        {item.planName}
                                      </small>
                                    </td>
                                    <td>
                                      <p className="table-text-right">
                                        {item.planPrice &&
                                          generatePrice(item.planPrice)}
                                      </p>
                                    </td>
                                  </tr>
                                )
                              )}
                          </>
                        )}
                        <tr
                          className={success && !appliedSuccess ? 'd-none' : ''}
                        >
                          <td className="discount">
                            <input
                              type="text"
                              className="form-control discount-input"
                              onChange={(e) => {
                                setDiscount(e.target.value);
                                setError('');
                              }}
                              value={discount}
                              name="discount"
                              disabled={appliedSuccess}
                            />
                            {error && (
                              <div className="text-danger m-1">{error}</div>
                            )}
                            {appliedSuccess && (
                              <>
                                <a
                                  className={
                                    isPaymentSuccess
                                      ? 'discount-remove disable'
                                      : 'discount-remove '
                                  }
                                  onClick={handleRemove}
                                >
                                  Remove
                                </a>
                                <p className="text-success">
                                  Coupon code applied successfully.
                                  <u className="remove-coupon ms-5 "></u>
                                </p>
                              </>
                            )}
                          </td>
                          <td className="table-text-right">
                            <button
                              type="button"
                              className={` apply-button ${
                                appliedSuccess ? 'disabled' : ''
                              }`}
                              onClick={handleApply}
                              disabled={appliedSuccess}
                            >
                              {isLoadingCoupon ? (
                                <ThreeDots
                                  height="25"
                                  width="40"
                                  radius="9"
                                  color="#fff"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              ) : (
                                'Apply'
                              )}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="plan">Subtotal</p>
                          </td>
                          <td>
                            <p className="table-text-right">
                              {billingDetail?.subTotal &&
                                generatePrice(billingDetail?.subTotal)}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p className="plan">Tax</p>
                          </td>
                          <td>
                            <p className="table-text-right">
                              {billingDetail?.tax &&
                                generatePrice(billingDetail?.tax)}
                            </p>
                          </td>
                        </tr>
                        {appliedSuccess && (
                          <tr>
                            <td>
                              <p className="plan">Discount</p>
                            </td>
                            <td>
                              <p className="table-text-right">
                                {discountApplied &&
                                discountApplied?.type === 'percentage'
                                  ? discountApplied?.amount + '%'
                                  : '$' +
                                    (discountApplied?.amount / 100).toFixed(2)}
                              </p>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td>
                            <p className="plan" style={{ fontWeight: '600' }}>
                              Total
                            </p>
                          </td>
                          <td>
                            <p
                              className="table-text-right"
                              style={{
                                fontWeight: '600',
                                width: '100px',
                                marginLeft: 'auto',
                              }}
                            >
                              USD {billingDetail?.total && generatePrice(total)}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="continue-btn">
                  <button
                    type="button"
                    className={`theme-button black-button login-button my-0`}
                    onClick={() => navigate('/account', { replace: true })}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </form>
  );
};

const Payment = () => {
  return (
    <div className="border-top">
      <div className="container-fluid">
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
    </div>
  );
};

export default Payment;
