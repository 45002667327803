import React, { useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { emailRegExp } from '../utils/common';
import { checkEmail } from '../utils/endpoint';
import { postApi } from '../utils/api';

const JoinForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ email }, { abortEarly: false });
      postApi(checkEmail, { email: email })
        .then((res) => {
          setError('');
          if (res.data?.data) {
            localStorage.setItem('joinEmail', email);
            navigate('/plan-checkout');
          } else {
            navigate('/login');
          }
        })
        .catch((e) => {
          console.log(e.response?.data);
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setError(validationErrors);
    }
  };

  return (
    <>
      <MainLayout>
        <div className="submit-list">
          <div className="submit login-height">
            <h3>Nice Choice! Lets Get Started.</h3>
            <label htmlFor="name" className="label-text">
              What’s your email address?
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mb-1 input-outline"
              // placeholder="Email Address"
            />
            {error && error.email && (
              <p style={{ color: 'red' }}>{error.email}</p>
            )}
            <div className="text-center mt-3">
              <a onClick={handleSubmit} className="theme-button login-button">
                Get Started
              </a>
            </div>
            <p className="account">
              Already have an account? <a href="/login">Log In</a>
            </p>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default JoinForm;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
});
