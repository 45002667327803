import React, { useRef, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { forgotPassword } from '../utils/endpoint';
import { postApi } from '../utils/api';
import { Link, useNavigate } from 'react-router-dom';
import { setLocalData } from '../utils/Storage';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();

  const handleForm = (e) => {
    e.preventDefault();
    setError(false);
    const formdata = new FormData();
    formdata.append('email', email);
    postApi(forgotPassword, formdata)
      .then((res) => {
        if (res?.data?.success) {
          setEmail('');
          setLocalData(
            'isResetPasswordLinkSent',
            "We've sent you an email with a link to update your password."
          );
          navigate('/login');
        } else {
          setError(true);
          setEmail('');
        }
      })
      .catch((e) => {
        setError(true);
        setEmail('');
        console.log(e);
      });
  };

  const handleManualSubmit = (e) => {
    e.preventDefault();
    setError(false);
    const form = formRef.current;
    if (form.checkValidity()) {
      handleForm(e);
    } else {
      const errorClass = document.getElementsByClassName('reset-password')[0];
      errorClass.classList.add('border-alert');
      setError(true);
      form.reportValidity();
    }
  };

  const handleInput = (e) => {
    setEmail(e.target.value);
    handleError();
  };
  const handleError = () => {
    const errorClass = document.getElementsByClassName('reset-password')[0];
    errorClass.classList.remove('border-alert');
  };
  return (
    <>
      <MainLayout>
        <div className="log-in-from login-height">
          <div className="container">
            <div className="row">
              <div className="log-in-form">
                <h3 className="log-account-text reset-password" tabIndex={'-1'}>
                  Reset your password
                </h3>
                <form ref={formRef}>
                  <p className="reset-password-change">
                    We will send you an email to reset your password
                  </p>
                  <div className="">
                    <label htmlFor="email" className="label-text">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control input-outline mb-1"
                      // placeholder="Email Address"
                      value={email}
                      onChange={handleInput}
                      required
                    />
                    {error && (
                      <>
                        <small>
                          <img
                            src="../../images/red_error.svg"
                            alt=""
                            className="img-fluid m-2"
                          />
                          No account found with that email.
                        </small>
                      </>
                    )}
                  </div>

                  <div className="text-center">
                    <Link
                      className="button-golden px-5 login-button mt-4"
                      onClick={handleManualSubmit}
                    >
                      Submit
                    </Link>
                  </div>
                  <Link to="/login" className="forgot-text mt-3">
                    Cancel
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default PasswordReset;
