import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Index from './Pages/Index';
import About from './Pages/About';
import Education from './Pages/Education';
import Coverage from './Pages/Coverage';
import Events from './Pages/Events';
import FaqPage from './Pages/FaqPage';
import JoinForm from './Pages/JoinForm';
import LogIn from './Pages/LogIn';
import PasswordReset from './Pages/PasswordReset';
import CarryResponsibly from './Pages/CarryResponsibly';
import RightToBlog from './Pages/RightToBlog';
import RightToBearPersonal from './Pages/RightToBearPersonal';
import OpenCarry from './Pages/OpenCarry';
import Structured from './Pages/Structured';
import Considerations from './Pages/Considerations';
import Stabilizing from './Pages/Stabilizing';
import Mandated from './Pages/Mandated';
import CompanyVision from './Pages/CompanyVision';
import Support from './Pages/Support.jsx';
import AffiliateNetWork from './Pages/AffiliateNetWork';
import TermsConditions from './Pages/TermsConditions';
import GovernmentFirearm from './Pages/GovernmentFirearm';
import QualitiesDefensiveHandgun from './Pages/QualitiesDefensiveHandgun';
import InstructorPortal from './Pages/InstructorPortal';
import PlanCheckOut from './Pages/PlanCheckOut';
import ScrollToTop from './utils/ScrollToTop';
import Account from './Pages/Account';
import ProfilePreferences from './Pages/ProfilePreferences';
import { getAuthToken, getUser } from './utils/Storage';
import CheckOut from './Pages/CheckOut';
import Overview from './Pages/Overview';
import AccountCoverage from './Pages/AccountCoverage';
import AccountBilling from './Pages/AccountBilling';
import GetHelpNow from './Pages/GetHelpNow';
import AccountResetPassword from './Pages/AccountResetPassword';
import { AccountAddresses } from './Pages/AccountAddresses';
import AccountOrder from './Pages/AccountOrder';
import MinorHouseholdChildrenYearly from './Pages/MinorHouseholdChildrenYearly';
import CoveragePlan from './Pages/CoveragePlan';
import PropTypes from 'prop-types';
import Referral from './Pages/Referral';
import { useEffect } from 'react';
import CcwInsurance from './Pages/CcwInsurance';
import EventView from './Pages/EventView.jsx';

function App() {
  function RequireAuth({ children, redirectTo }) {
    let userToken = getAuthToken('userToken');
    return userToken ? children : <Navigate to={redirectTo} />;
  }
  function RequireUser({ children, redirectTo }) {
    let userEmail = getUser('joinEmail');
    return userEmail ? children : <Navigate to={redirectTo} />;
  }

  function RequireToken({ children, redirectTo }) {
    let userToken = getAuthToken('userToken') || getAuthToken('userToken');
    return userToken ? <Navigate to={redirectTo} /> : children;
  }
  useEffect(() => {
    const preventZoom = (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
      }
    };

    // Attach an event listener to capture zoom events
    window.addEventListener('wheel', preventZoom, { passive: false });

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener('wheel', preventZoom);
    };
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route
            path="/"
            element={
              <RequireToken redirectTo="/overview">
                <Index />
              </RequireToken>
            }
          />
          <Route
            path="/about"
            element={
              <RequireToken redirectTo="/overview">
                <About />
              </RequireToken>
            }
          />
          <Route
            path="/education"
            element={
              <RequireToken redirectTo="/overview">
                <Education />
              </RequireToken>
            }
          />

          <Route
            path="/coverage"
            element={
              <RequireToken redirectTo="/overview">
                <Coverage />
              </RequireToken>
            }
          />

          <Route
            path="/events"
            element={
              <RequireToken redirectTo="/overview">
                <Events />
              </RequireToken>
            }
          />
          <Route
            path="/faqpage"
            element={
              <RequireToken redirectTo="/overview">
                <FaqPage />
              </RequireToken>
            }
          />

          <Route
            path="/joinform"
            element={
              <RequireToken redirectTo="/overview">
                <JoinForm />
              </RequireToken>
            }
          />
          <Route
            path="/login"
            element={
              <RequireToken redirectTo="/overview">
                <LogIn />
              </RequireToken>
            }
          />
          <Route
            path="/passwordreset"
            element={
              <RequireToken redirectTo="/overview">
                <PasswordReset />
              </RequireToken>
            }
          />

          <Route path="/carryresponsibly" element={<CarryResponsibly />} />
          <Route path="/righttoblog" element={<RightToBlog />} />
          <Route
            path="/right-to-bear-personal"
            element={<RightToBearPersonal />}
          />
          <Route path="/open-carry" element={<OpenCarry />} />
          <Route path="/structured" element={<Structured />} />
          <Route path="/considerations" element={<Considerations />} />
          <Route path="/stabilizing" element={<Stabilizing />} />
          <Route path="/mandated" element={<Mandated />} />
          <Route path="/Company-vision" element={<CompanyVision />} />
          <Route path="/support" element={<Support />} />
          <Route path="/affiliate-net-work" element={<AffiliateNetWork />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/government-firearm" element={<GovernmentFirearm />} />
          <Route path="/account-order" element={<AccountOrder />} />
          <Route path="/ccw-insurance" element={<CcwInsurance />} />
          <Route path="/event-view" element={<EventView />} />

          <Route
            path="/qualities-defensive-handgun"
            element={<QualitiesDefensiveHandgun />}
          />
          <Route path="/instructor-portal" element={<InstructorPortal />} />
          <Route path="/get-help-now" element={<GetHelpNow />} />
          <Route
            path="/account-reset-password?"
            element={<AccountResetPassword />}
          />
          <Route
            path="/minor-house-hold-children-yearly"
            element={<MinorHouseholdChildrenYearly />}
          />

          {/* Route After login  */}

          <Route
            path="/overview"
            element={
              <RequireAuth redirectTo="/login">
                <Overview />
              </RequireAuth>
            }
          />

          <Route
            path="/plan-checkout"
            element={
              <RequireUser redirectTo="/coverage">
                <PlanCheckOut />
              </RequireUser>
            }
          />

          <Route
            path="/checkouts"
            element={
              <RequireUser redirectTo="/coverage">
                <CheckOut />
              </RequireUser>
            }
          />

          <Route
            path="/profile-preferences"
            element={
              <RequireAuth redirectTo="/login">
                <ProfilePreferences />
              </RequireAuth>
            }
          />

          <Route
            path="/account"
            element={
              <RequireAuth redirectTo="/login">
                <Account />
              </RequireAuth>
            }
          />
          <Route
            path="/account-coverage"
            element={
              <RequireAuth redirectTo="/login">
                <AccountCoverage />
              </RequireAuth>
            }
          />
          <Route
            path="/account-billing"
            element={
              <RequireAuth redirectTo="/login">
                <AccountBilling />
              </RequireAuth>
            }
          />
          <Route
            path="/account-addresses"
            element={
              <RequireAuth redirectTo="/login">
                <AccountAddresses />
              </RequireAuth>
            }
          />
          <Route
            path="/minor-house-hold-children-yearly"
            element={
              <RequireAuth redirectTo="/login">
                <MinorHouseholdChildrenYearly />
              </RequireAuth>
            }
          />
          <Route path="/plans" element={<CoveragePlan />} />
          <Route path="/ref" element={<Referral />} />
        </Routes>
        <ScrollToTop />
      </BrowserRouter>
    </>
  );
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  redirectTo: PropTypes.string,
};

export default App;
