import React from 'react';
import { Link } from 'react-router-dom';

const AccountFooter = () => {
  return (
    <>
      <footer>
        <div className="footer__content-bottom">
          <div className="container">
            <div className="row">
              <div className="right-to-bear-footer">
                <div className="copy-right order-3 order-lg-1">
                  <span>
                    © 2023,<Link to="/">Right To Bear</Link>
                  </span>
                </div>
                <div className="bottom-footer-term-condition order-2 order-lg-2">
                  <a href="/terms-conditions">Terms & Conditions</a>
                </div>
                <div className="footer__list-social order-1 order-lg-3">
                  <ul>
                    <li>
                      <Link to="https://twitter.com/RTBAssociation">
                        <i className="fa fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="ttps://www.facebook.com/righttobearlegaldefense/?show_switched_toast=0&show_invite_t[…]w_community_rollback=0&show_follower_visibility_disclosure=0">
                        <i className="fa fa-facebook-official"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to=" https://www.youtube.com/channel/UCWpAALDAKY5bk7U_cHoxvDQ">
                        <i className="fa fa-youtube-play"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default AccountFooter;
