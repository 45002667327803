import React from 'react';
import MainLayout from '../Layout/MainLayout';
import PackageForm from '../components/PackageForm';
import Video from '../components/Video';

const Coverage = () => {
  return (
    <>
      <MainLayout>
        <div className="select-user-plan">
          <h4 className="select-heading">JOIN NOW</h4>
          <h4 className="sub-title">Select Your Plan to Get Started!</h4>
        </div>
        <PackageForm />

        <div className="coverage">
          <div className="container">
            <div className="rwo">
              <div className="coverage-police">
                <h3 className="sub-title">
                  COVERAGES offered by Right To Bear:
                </h3>

                <div className="cover-ages-text">
                  <p>
                    Civil Defense coverage allows you to choose your own
                    attorney in the event you are sued after a self-defense
                    incident.
                  </p>
                  <p>
                    Criminal Defense coverage provides legal support in the
                    event you are criminally charged after a self-defense
                    incident. (You choose your own attorney)
                  </p>
                  <p>
                    Multi state coverage offers you protection in 50 states.
                    Residents of New York, New Jersey, and Washington do not
                    qualify for our membership.
                  </p>
                  <p>
                    Expert Witness coverage will provide an “expert witness” to
                    testify on your behalf.
                  </p>
                  <p>
                    Psychological support provides coverage for counseling
                    sessions due to stress/trauma experienced from a
                    self-defense incident.
                  </p>
                  <p>
                    Bail Bond Reimbursement provides money for bail up to
                    $100,000.
                  </p>
                  <p>
                    Additional Associate Coverage extends coverage to a spouse
                    or household partner.
                  </p>
                  <p>
                    Gun replacement – We will replace your gun if taken after a
                    self-defense incident.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '35px' }}>
          <Video />
        </div>
      </MainLayout>
    </>
  );
};

export default Coverage;
