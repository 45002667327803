import React from 'react';
import MainLayout from '../Layout/MainLayout';
import Accordion from '../components/Accordion';

const FaqPage = () => {
  return (
    <>
      <MainLayout>
        <div className="about-banner faq-banner">
          <div className="about-outer">
            <h1 className="banner-heading">FREQUENTLY ASKED QUESTIONS</h1>
          </div>
        </div>
        <Accordion heading="MEMBERSHIP FACTS & FAQS" />
      </MainLayout>
    </>
  );
};

export default FaqPage;
