import React, { useEffect, useState } from 'react';

const Header = () => {
  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    const path = window.location.pathname;
    setActiveItem(path);
  }, []);

  return (
    <>
      <div className="navbar d-lg-block d-none">
        <div className="container">
          <header>
            <a href="/" className="logo">
              <img src="../../images/footer-logo.webp" alt="" />
            </a>
            <nav>
              <ul className="menu-bar">
                <li className={activeItem === '/about' ? 'active' : ''}>
                  <a href="/about">about</a>
                </li>
                <li className={activeItem === '/education' ? 'active' : ''}>
                  <a href="/education">education</a>
                </li>

                <li className={activeItem === '/coverage' ? 'active' : ''}>
                  <a href="/coverage">get coverage</a>
                </li>
                <li className={activeItem === '/events' ? 'active' : ''}>
                  <a href="/events">events</a>
                </li>
                <li className={activeItem === '/faqpage' ? 'active' : ''}>
                  <a href="/faqpage">faq</a>
                </li>
              </ul>
            </nav>
            <div className="log-in">
              <a
                href="/login"
                className={activeItem === '/login' ? 'active' : ''}
              >
                LOG IN
              </a>
            </div>
          </header>
        </div>
      </div>
      <div className="navbar navbar-mobile-menu d-lg-none d-block">
        <div className="container-fluid">
          <header>
            <a href="/" className="logo">
              <img src="../../images/logo.webp" alt="" />
            </a>
            <nav>
              <input type="checkbox" id="click" />
              <label htmlFor="click" className="menu-btn">
                <i className="fa fa-align-right"></i>
              </label>
              <ul className="menu-bar">
                <li className={activeItem === '/login' ? 'active' : ''}>
                  <a href="/login">LOG IN</a>
                </li>
                <li className={activeItem === '/about' ? 'active' : ''}>
                  <a href="/about">about</a>
                </li>
                <li className={activeItem === '/education' ? 'active' : ''}>
                  <a href="/education">education</a>
                </li>

                <li className={activeItem === '/coverage' ? 'active' : ''}>
                  <a href="/coverage">get coverage</a>
                </li>
                <li className={activeItem === '/events' ? 'active' : ''}>
                  <a href="/events">events</a>
                </li>
                <li className={activeItem === '/faqpage' ? 'active' : ''}>
                  <a href="/faqpage">faq</a>
                </li>
                <li
                  className={activeItem === '/Company-vision' ? 'active' : ''}
                >
                  <a href="/Company-vision">Company Vision</a>
                </li>
                <li
                  className={activeItem === '/carryresponsibly' ? 'active' : ''}
                >
                  <a href="/carryresponsibly">Carry Responsibly</a>
                </li>
                <li
                  className={
                    activeItem === '/instructor-portal' ? 'active' : ''
                  }
                >
                  <a href="/instructor-portal">Instructor Portal</a>
                </li>
                <li className={activeItem === '/support' ? 'active' : ''}>
                  <a href="/support">Help & Support</a>
                </li>
                <li
                  className={
                    activeItem === '/affiliate-net-work' ? 'active' : ''
                  }
                >
                  <a href="/affiliate-net-work">Affiliate Network</a>
                </li>
              </ul>
            </nav>
          </header>
        </div>
      </div>
    </>
  );
};

export default Header;
