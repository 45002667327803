import React, { useEffect, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { useDispatch } from 'react-redux';
import { setPersonalDetail } from '../redux/features/signup/signupSlice';
import { postApi } from '../utils/api';
import { login } from '../utils/endpoint';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { deleteLocalData, getLocalData } from '../utils/Storage';

const LogIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [data] = useState({
    success: getLocalData('isResetPasswordLinkSent') ? true : false,
    message: getLocalData('isResetPasswordLinkSent')
      ? getLocalData('isResetPasswordLinkSent')
      : '',
  });

  const dispatch = useDispatch();

  const handleLogin = () => {
    setError(false);
    if (email !== '' && password !== '') {
      const formdata = new FormData();
      formdata.append('email', email);
      formdata.append('password', password);
      postApi(login, formdata)
        .then((res) => {
          dispatch(setPersonalDetail(res.data.data));
          Cookies.set('userToken', res.data.data.token, { expires: 7 });
          if (res.data?.data?.subscriptions?.length === 0) {
            navigate('/plan-checkout');
          } else {
            navigate('/account');
          }
          setError(false);
          setEmail('');
          setPassword('');
        })
        .catch((e) => {
          console.log(e);
          setError(true);
          setEmail('');
          setPassword('');
        });
    } else {
      setError(true);
      setEmail('');
      setPassword('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', function () {
      const errorClass = document.getElementsByClassName('login-alert');
      if (errorClass.length !== 0 && data?.success) {
        for (const element of errorClass) {
          element.classList.remove('border-alert');
        }
      }
    });
  }, []);

  const styleClass = {
    color: '#121212BF',
    marginLeft: '20px',
  };

  useEffect(() => {
    const clearLocalStorageOnUnload = () => {
      deleteLocalData('isResetPasswordLinkSent');
    };
    window.addEventListener('beforeunload', clearLocalStorageOnUnload);
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorageOnUnload);
    };
  }, []);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <MainLayout>
        <div className="log-in-from login-height">
          <div className="container">
            <div className="row">
              <div className="log-in-form">
                <h3 className="log-account-text">LOG IN TO YOUR ACCOUNT</h3>
                <form>
                  {error && (
                    <>
                      <h6 tabIndex={'-1'} autoFocus="" className="border-alert">
                        <img
                          src="../../images/red_error.svg"
                          alt=""
                          className="img-fluid m-2"
                        />
                        Please adjust the following:
                      </h6>
                      <div style={styleClass}>
                        <ul>
                          <li>Incorrect email or password.</li>
                        </ul>
                      </div>
                    </>
                  )}
                  {data && data?.success && !error && (
                    <>
                      <div className="login-alert border-alert">
                        <img
                          src="../../images/check_green.svg"
                          alt=""
                          className="img-fluid m-2"
                        />
                        <h6 tabIndex={'-1'} autoFocus="" className="mb-0">
                          {data?.message}
                        </h6>
                      </div>
                    </>
                  )}
                  <label htmlFor="name" className="label-text">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="input-outline"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <label htmlFor="name" className="label-text">
                    Password*
                  </label>
                  <div className="ps">
                    <input
                      type="password"
                      className="input-outline"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span>
                      <span className="input-group-text">
                        {showPassword ? (
                          <img
                            src="../../images/eye-password-hide.svg"
                            alt=""
                            onClick={togglePasswordVisibility}
                          />
                        ) : (
                          <img
                            src="../../images/eye-password-show.svg"
                            alt=""
                            onClick={togglePasswordVisibility}
                          />
                        )}
                      </span>
                    </span>
                  </div>
                  <div className="text-center">
                    <a
                      onClick={handleLogin}
                      className="button-golden px-5 login-button"
                    >
                      Log In
                    </a>
                  </div>

                  <p className="account-text">
                    Don&apos;t have an account?{' '}
                    <Link to="/joinform" className="get-text">
                      Get Coverage
                    </Link>
                    <Link to="/passwordreset" className="forgot-text pt-4">
                      Forgot password?
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default LogIn;
