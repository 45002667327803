import React, { useEffect, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import { Link, useLocation } from 'react-router-dom';
import { eventsApi } from '../utils/endpoint';
import { getApi } from '../utils/api';
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
const EventView = () => {
  const { state } = useLocation();
  const { data } = state || {};
  const [eventList, setEventList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getApi(eventsApi)
      .then((res) => {
        if (res?.data?.success) {
          setEventList(res?.data?.data);
          if (data && data?.id) {
            const index = res.data.data.findIndex(
              (event) => event.id === data.id
            );
            setCurrentIndex(index !== -1 ? index : 0);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [data]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, eventList.length - 1)
    );
    window.scrollTo(0, 0);
  };
  return (
    <>
      <MainLayout>
        {isLoading && (
          <div className="loader">
            <ThreeDots
              height="110"
              width="110"
              radius="10"
              color="#aeaeae"
              ariaLabel="three-dots-loading"
              wrapperStyle={{ display: 'contents' }}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}
        {!isLoading && (
          <div className="personal-gun">
            <div className="container">
              <div className="row">
                <div className="insurance-gun-user">
                  <img
                    src={
                      eventList[currentIndex]?.image ||
                      '../../images/event-3.jpg'
                    }
                    alt=""
                  />
                </div>
                <div className="handgun">
                  <h3 className="handgun-title">
                    {eventList[currentIndex]?.title}
                  </h3>
                  <p className="handgun-sub-title">
                    {moment(eventList[currentIndex]?.date).format(
                      'MMMM D, YYYY'
                    )}
                  </p>
                </div>
                <div className="protection">
                  <p
                    className="gun-link-firearm"
                    dangerouslySetInnerHTML={{
                      __html: eventList[currentIndex]?.description.replace(
                        /\r\n/g,
                        '<br/>'
                      ),
                    }}
                  ></p>

                  <div className="s-p">
                    <Link to="#"> Sign Up</Link>
                  </div>

                  <div className="p-n">
                    <Link
                      to="#"
                      className={
                        currentIndex === 0
                          ? 'previous  disabled-btn'
                          : 'previous'
                      }
                      onClick={handlePrevious}
                      disabled={currentIndex === 0}
                    >
                      &laquo; Previous
                    </Link>
                    <Link to="/experiences" className="exp">
                      <i className="fa fa-long-arrow-left me-2"></i>
                      Back to Experience
                    </Link>
                    <Link
                      to="#"
                      className={
                        currentIndex === eventList.length - 1
                          ? 'next-2 disabled-btn'
                          : 'next-2'
                      }
                      onClick={handleNext}
                    >
                      Next &raquo;
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </MainLayout>
    </>
  );
};

export default EventView;
