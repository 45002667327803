import React from 'react';
import { Link } from 'react-router-dom';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { paymentMethodApi } from '../utils/endpoint';
import Cookies from 'js-cookie';
import { postApi } from '../utils/api';
import PropTypes from 'prop-types';

const PaymentForm = ({ Heading, ButtonFirst, fetchPaymentMethod }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMsg, SetErrorMsg] = useState('');
  const [isError, SetIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        SetIsError(true);
        SetErrorMsg(error.message);
        setTimeout(() => {
          SetIsError(false);
        }, 4000);
        setIsLoading(false);
      }

      if (paymentMethod) {
        const formdata = new FormData();
        formdata.append('payment_method', paymentMethod.id);
        let access_token = Cookies.get('userToken');
        postApi(paymentMethodApi, formdata, access_token)
          .then((res) => {
            if (res.data.success) {
              setIsLoading(false);
              setSuccess(true);
              if (elements) {
                elements.getElement(CardNumberElement).clear();
                elements.getElement(CardExpiryElement).clear();
                elements.getElement(CardCvcElement).clear();
              }
              const modalClose =
                document.getElementsByClassName('cancel-add')[0];
              modalClose.click();

              fetchPaymentMethod();
            } else {
              SetIsError(true);
              SetErrorMsg('Something went wrong. Please try again!');
              setTimeout(() => {
                SetIsError(false);
              }, 4000);
              setIsLoading(false);
            }
          })
          .catch((e) => {
            SetIsError(true);
            SetErrorMsg(e.response?.data?.message);
            setTimeout(() => {
              SetIsError(false);
            }, 4000);
            setIsLoading(false);
          });
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  return (
    <>
      <div className="container">
        <form id="earlyForm" onSubmit={handleSubmit}>
          <div className="row">
            <h2 className="profile pro-changes">{Heading}</h2>
            <div className="col-12">
              {isError && (
                <h6 className="main-heading text-heading text-align-list text-center text-danger">
                  {errorMsg}
                </h6>
              )}
            </div>
            <div className="col-lg-12 px-lg-3 px-md-0 px-0 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <CardNumberElement className="form-control input-outline" />
                </div>
                <div className="col-lg-6">
                  <CardExpiryElement className="form-control input-outline" />
                </div>
                <div className="col-lg-6">
                  <CardCvcElement className="form-control input-outline" />
                </div>
              </div>
            </div>
            <div className="payment-btn-add">
              <button
                type="submit"
                className={`button-black size-button w-25  ${
                  !stripe || !elements || (success && 'disable')
                }`}
              >
                {isLoading ? (
                  <ThreeDots
                    height="21"
                    width="15"
                    radius="9"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  ButtonFirst
                )}
              </button>
              <Link
                to="#"
                id="cancel-id"
                className="button-black  size-button size-border cancel-add w-25 my-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  if (elements) {
                    elements.getElement(CardNumberElement).clear();
                    elements.getElement(CardExpiryElement).clear();
                    elements.getElement(CardCvcElement).clear();
                  }
                }}
              >
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

PaymentForm.propTypes = {
  Heading: PropTypes.string,
  ButtonFirst: PropTypes.string,
  fetchPaymentMethod: PropTypes.func,
};
export default PaymentForm;
