import React, { useRef, useState } from 'react';
import MainLayout from '../Layout/MainLayout';
import Video from '../components/Video';
import PackageForm from '../components/PackageForm';
import { postApi } from '../utils/api';
import { contact } from '../utils/endpoint';
import { Link } from 'react-router-dom';

const Index = () => {
  const [contactUsForm, setContactUsForm] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    comment: '',
  });
  const [isResponse, setIsResponse] = useState(false);

  const [responseMessage, setResponseMessage] = useState('');

  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('name', contactUsForm.name);
    formdata.append('email', contactUsForm.email);
    formdata.append('phone', contactUsForm.phoneNumber);
    formdata.append('contact', contactUsForm.comment);
    await postApi(contact, formdata)
      .then((res) => {
        if (res.data.success) {
          setContactUsForm({
            name: '',
            email: '',
            phoneNumber: '',
            comment: '',
          });
          setIsResponse(true);
          setResponseMessage(
            "Thanks for contacting us. We'll get back to you as soon as possible."
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactUsForm((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleManualSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;
    if (form.checkValidity()) {
      await handleSubmit(e);
    } else {
      form.reportValidity();
    }
  };
  return (
    <>
      <MainLayout>
        <div className="banner">
          <div className="container">
            <div className="row">
              <div className="banner-outre">
                <h1 className="banner-heading">SAFETY FIRST, GET PROTECTED</h1>
                <p className="outer-title">
                  Responsibly protect yourself and your loved ones with the BEST
                  self defense association in the country.
                </p>
                <a
                  href="/coverage"
                  className="button-size theme-button button-white "
                >
                  JOIN NOW
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="liberty-outer">
          <div className="container">
            <div className="row">
              <h3 className="title-text">
                SPEAK FREELY | LIVE FULLY | DEFEND LIBERTY
              </h3>
              <p className="sub-title-list">
                <span className="ff">&quot;</span>Our family has worked with and
                seen firsthand how other legal defense companies have treated
                their clients as just another number. I truly believe in my
                heart of hearts that we would not have received the amount of
                care and compassion like we have received from RTB
                <span className="ff">!&quot;</span>
              </p>
              <p className="right-to-bear-text">-Right to Bear Member</p>
            </div>
          </div>
        </div>
        <div className="liberty-outer right-to">
          <div className="container">
            <div className="row">
              <h3 className="title-text">RIGHT TO BEAR</h3>
              <p className="ranked">Ranked #1 by LynxDefense</p>
              <img src="../images/logo-med.png" alt="" className="img-fluid" />
              <p className="review">
                See their review -
                <Link to="https://lynxdefense.com/best-concealed-carry-insurance/">
                  Best Concealed Carry Insurance
                </Link>
              </p>
              <h2 className="find-heading">
                Find out how firearm legal protection can benefit you!
              </h2>
            </div>
          </div>
        </div>
        <Video />
        <div className="liberty-outer">
          <div className="container">
            <div className="row">
              <h3 className="title-text">OUR MISSION</h3>
              <p className="sub-title-list">
                To serve out member, partners and RTB team faithfully by making
                a positive difference daily. First by letting them know we care
                and second by protecting what they value most- Faith, and
                Freedom. We are relentless in our efforts of continuous
                improvement, both personally and professionally, and strive to
                do things better. by investing in our people and creating a
                culture of collaboration, selflessness, and grit, we are
                building a team to protect your team,
              </p>
              <p className="right-to-bear-text">-Right to Bear Member</p>
            </div>
          </div>
        </div>
        <PackageForm />
        <div className="responsibly">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6 g-0  order-md-2">
                <div className="gun-images ">
                  <img src="../../images/guns.jpg" alt="" className="w-100" />
                </div>
              </div>
              <div className="col-md-6 order-md-1">
                <div className="responsibly-user-text">
                  <h3 className="sub-title">
                    PROTECT YOUR FAMILY, RESPONSIBLY.
                  </h3>
                  <p className="sub-text-user">
                    Right To Bear offers Elite protection along with many other
                    great benefits. Our plans offer more than just legal
                    reimbursement to secure your peace of mind.
                  </p>

                  <div className="list-pointer">
                    <ul>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Civil Defense</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Criminal Defenses</p>
                      </li>
                      <li className="d-flex align-items-start">
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid list-image pt-2"
                        />
                        <p>
                          All states in the United States except New York, New
                          Jersey and Washington,
                        </p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Psychology Support</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Expert Witness Defense</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Gun Replacement</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Accidental Discharge</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Self Defense Training Videos</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Discount to Industry Partners</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Spousal Coverage *</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Minor Children Defense *</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>Bail Bond Coverage ($100,000) max *</p>
                      </li>
                      <li>
                        <img
                          src="../../images/shield_svg.svg"
                          alt=""
                          className="img-fluid "
                        />
                        <p>24/7 Attorney Answered Hotline</p>
                      </li>
                    </ul>
                    <a
                      href="/coverage"
                      className="theme-button button-white mobile-width"
                      style={{
                        padding: '18px 80px',
                        border: '1px solid #b6632f',
                      }}
                    >
                      JOIN NOW
                    </a>
                    <p className="list-available">
                      *Available as an optional add-on
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shopify-section-template">
          <div className="container">
            <div className="row">
              <div className="template">
                <div className="shopify mb-4">
                  <h3 className="section-heading">STILL HAVE QUESTIONS?</h3>
                  <p className="email-text">
                    Contact our customer service team at{' '}
                    <a
                      href="mailto:info@protectwithbear.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      info@protectwithbear.com
                    </a>
                  </p>
                  {isResponse && (
                    <>
                      <div className="check-img-and-text">
                        <img src="../images/check_green.svg" alt="" />
                        &nbsp;
                        <p>{responseMessage}</p>
                      </div>
                    </>
                  )}
                </div>

                <form id="earlyAccessForm" ref={formRef}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="name"
                          name="name"
                          className="form-control form-input"
                          value={contactUsForm.name}
                          onChange={handleInputChange}
                          required
                        />
                        <label htmlFor="floatingInput">Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          name="email"
                          className="form-control form-input"
                          value={contactUsForm.email}
                          onChange={handleInputChange}
                          required
                        />
                        <label>Email*</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          className="form-control form-input"
                          name="phoneNumber"
                          type="tel"
                          maxLength="10"
                          onKeyPress={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (!regex.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          value={contactUsForm.phoneNumber}
                          onChange={handleInputChange}
                        />
                        <label>Phone Number</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control form-input"
                          style={{ height: '100px' }}
                          name="comment"
                          value={contactUsForm.comment}
                          onChange={handleInputChange}
                        ></textarea>
                        <label htmlFor="floatingTextarea2">Comments</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        form="earlyAccessForm"
                        className="from-button"
                        onClick={handleManualSubmit}
                      >
                        Submit
                      </button>
                    </div>
                    <p className="form-text-last">
                      By completing this form, I have read and acknowledged the
                      Privacy Statement and agree that Right to Bear may contact
                      me at the email address or phone number above
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default Index;
