import React, { useRef, useState } from 'react';
import AccountMainLayout from '../Layout/AccountMainLayout';
import { Link, useNavigate } from 'react-router-dom';
import { passwordRegExp } from '../utils/common';
import * as Yup from 'yup';
import { postApi } from '../utils/api';
import { resetPassword } from '../utils/endpoint';
import Cookies from 'js-cookie';
import { setPersonalDetail } from '../redux/features/signup/signupSlice';
import { useDispatch } from 'react-redux';

const AccountResetPassword = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get('email');
  const token = queryParameters.get('token');
  const [errors, setErrors] = useState('');
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const formRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const styleClass = {
    color: 'red',
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    try {
      if (submitError) {
        handleError();
      }

      validationSchema.validateSyncAt(name, { [name]: value });
      if (name === 'password' && value !== formData.confirmPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: '',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: '',
        }));
      }
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    } catch (error) {
      if (name === 'confirmPassword' && value === formData.password) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: '',
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }));
      }
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, {
        abortEarly: false,
      });
      setErrors('');
      let access_token = Cookies.get('userToken');
      const formdata = new FormData();
      formdata.append('token', token);
      formdata.append('email', email);
      formdata.append('password', formData.password);
      formdata.append('password_confirmation', formData.confirmPassword);
      postApi(resetPassword, formdata, access_token)
        .then((res) => {
          if (res?.data?.success) {
            dispatch(setPersonalDetail(res?.data?.data));
            Cookies.set('userToken', res?.data?.data?.token, { expires: 7 });
            navigate('/account');
          }
        })
        .catch((err) => {
          setSubmitError(true);
          setErrorMessage('Something went wrong please try again later!');
          if (!err?.response.data?.success) {
            navigate('/login');
          }
        });
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });

      if (formData.password === '' && formData.confirmPassword === '') {
        setErrorMessage("Password can't be blank");
      } else if (formData.password !== '' && formData.confirmPassword === '') {
        setErrorMessage(
          "The password confirmation must match the provided password and can't be blank "
        );
      }
      if (submitError) {
        const errorClass = document.getElementsByClassName('set-password')[0];
        errorClass.classList.add('border-alert');
      }
      setSubmitError(true);
      setFormData({ password: '', confirmPassword: '' });
      setErrors(validationErrors);
    }
  };

  const handleError = () => {
    const errorClass = document.getElementsByClassName('set-password')[0];
    errorClass.classList.remove('border-alert');
  };

  return (
    <>
      <AccountMainLayout>
        <div className="account-login">
          <div className="container">
            <div className="row">
              <div className="log-in-form">
                <h3 className="log-account-text">Reset account password</h3>
                <p className="new-password">Enter a new password for {email}</p>
                <form ref={formRef}>
                  {submitError && (
                    <>
                      <h5
                        tabIndex={'-1'}
                        autoFocus=""
                        className="set-password border-alert"
                      >
                        <img
                          src="../../images/red_error.svg"
                          alt=""
                          className="img-fluid m-2"
                        />
                        Please adjust the following:
                      </h5>
                      <div className="ms-4">
                        <ul>
                          <li>{errorMessage}</li>
                        </ul>
                      </div>
                    </>
                  )}
                  <div className="form-floating ">
                    <input
                      type="password"
                      className="form-control form-input border mb-1 mt-md-0 mt-4"
                      name="password"
                      value={formData.password}
                      onChange={handlePasswordChange}
                    />
                    <label htmlFor="password">Password</label>
                  </div>
                  {errors.password && (
                    <div style={styleClass}>{errors.password}</div>
                  )}
                  <div className="form-floating">
                    <input
                      type="password"
                      className="form-control form-input border mb-1 mt-md-3 mt-4"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handlePasswordChange}
                    />
                    <label htmlFor="confirm">Confirm Password</label>
                  </div>
                  {errors.confirmPassword && (
                    <div className="mb-md-2 mb-4" style={styleClass}>
                      {errors.confirmPassword}
                    </div>
                  )}
                  <div className="text-center">
                    <Link
                      onClick={handleResetPassword}
                      className="button-golden login-button button-black"
                    >
                      reset password
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AccountMainLayout>
    </>
  );
};

export default AccountResetPassword;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(
      passwordRegExp,
      'Password must be between 8-40 characters, it must contain 1 upper case, 1 lower case, 1 number and 1 special character.'
    ),
  confirmPassword: Yup.string()
    .nullable()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], ''),
});
