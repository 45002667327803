import * as Yup from 'yup';
import { emailRegExp, passwordRegExp, phoneRegExp } from './common';

// Validation Schema
export const validationInformationSchema = Yup.object().shape({
  userType: Yup.string().required('User Type is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(emailRegExp, 'Please enter a valid Email Address'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Please enter valid mobile number'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      passwordRegExp,
      'Password must be between 8-40 characters, it must contain 1 upper case, 1 lower case, 1 number and 1 special character.'
    ),
  confirmPassword: Yup.string()
    .nullable()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password')], 'Password and Confirm Password should match'),
  DOB: Yup.date()
    .nullable()
    .required('Date of Birth is required')
    .transform((value, originalValue) => {
      if (originalValue === '') return null;
      return value;
    })
    .test('age', 'Invalid age', (value) => {
      if (!value) return false;
      const currentDate = new Date();
      const selectedDate = new Date(value);
      const minAgeDate = new Date();
      const maxAgeDate = new Date();
      minAgeDate.setFullYear(currentDate.getFullYear() - 18); // Minimum age of 18
      maxAgeDate.setFullYear(currentDate.getFullYear() - 95); // Maximum age of 95
      if (selectedDate > minAgeDate) {
        // Age less than 18
        throw new Yup.ValidationError('You have to be older than 18');
      } else if (selectedDate < maxAgeDate) {
        // Age greater than 95
        throw new Yup.ValidationError('You have to be younger than 95');
      }
      return true;
    }),
  address: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
  billingAddress: Yup.string(),
});

export function generatePrice(digit) {
  return '$' + Number(digit).toFixed(2);
}

export const handleTabAction = (currentTabId, nextTabId) => {
  const currentTabButton = document.querySelector(`#${currentTabId}-tab`);
  currentTabButton.classList.remove('active');

  const currentTabContent = document.querySelector(`#${currentTabId}`);
  currentTabContent.classList.remove('active');
  currentTabContent.classList.remove('show');

  const nextTabButton = document.querySelector(`#${nextTabId}-tab`);
  nextTabButton.classList.add('active');

  const nextTabContent = document.querySelector(`#${nextTabId}`);
  nextTabContent.classList.add('show', 'active');
  window.scrollTo(0, 0);
};

export const getGreeting = () => {
  const myDate = new Date();
  const hrs = myDate.getHours();
  if (hrs < 12) {
    return 'Good Morning';
  } else if (hrs >= 12 && hrs <= 17) {
    return 'Good Afternoon';
  } else if (hrs >= 17 && hrs <= 24) {
    return 'Good Evening';
  }
};
